<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-12">
            <strong>Golongan</strong>
            <a-button
              class="btn btn-outline-primary ml-4"
              @click="sendToForm('Create')"
              >Tambah Data</a-button
            >
          </div>
        </div>
      </div>
      <div class="card-body">
        <a-table
          style="margin-left: -26px; margin-right: -26px; margin-top: -21px;"
          :columns="columns"
          :dataSource="data"
          size="small"
          :customRow="customRow"
          :pagination="{
            hideOnSinglePage: true,
            defaultPageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }"
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px;"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px;"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            >
              Search
            </a-button>
            <a-button
              size="small"
              style="width: 90px;"
              @click="() => handleReset(clearFilters)"
            >
              Reset
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>
              <span>
                {{ text }}
              </span>
            </template>
          </template>
          <span slot="action" slot-scope="text, record">
            <a-icon
              type="edit"
              class="text-warning"
              @click="sendToForm('Update', record, record.id)"
            />
            <a-divider type="vertical" />
            <a-icon
              type="delete"
              class="text-danger"
              @click="showDeleteConfirm(record)"
            />
          </span>
          <template slot="appList" slot-scope="text, record">
            <a-tag v-for="(data, index) in record.appLists" :key="index">{{
              data.appName
            }}</a-tag>
          </template>
          <template slot="currency" slot-scope="text">
            {{ formatCurrency(text) }}
          </template>
        </a-table>
      </div>
    </div>
    <formModal ref="formModal" :key="componentKey" :table="table" />
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import { Modal } from 'ant-design-vue'
import formModal from './form'

export default {
  components: {
    formModal,
  },
  data() {
    return {
      name: 'TablesAntdBasic',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      popinput: {
        password: '',
        re_password: '',
      },
      rowIndexGotSpan: [],
      columns: [
        {
          title: 'Action',
          key: 'action',
          width: 70,
          scopedSlots: { customRender: 'action' },
        },
        {
          title: 'Keterangan',
          dataIndex: 'keterangan',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.keterangan.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Gaji Pokok',
          dataIndex: 'gaji_pokok',
          scopedSlots: { customRender: 'currency' },
          sorter: (a, b) => a.gaji_pokok - b.gaji_pokok,
        },
        {
          title: 'Tunjangan',
          dataIndex: 'tunjangan',
          scopedSlots: { customRender: 'currency' },
          sorter: (a, b) => a.tunjangan - b.tunjangan,
        },
      ],
      selectedidbpr: '',
      datapermission: [],
      table: 'golongan',
      form: this.$form.createForm(this, { name: 'Form ' + this.table }),
      data: [],
      backupdata: [],
      componentKey: 0,
    }
  },
  mounted: function () {
    this.getData()
  },
  methods: {
    findName(record) {
      if (record.appLists.length === 0) {
        return 'Tidak ada List app'
      } else {
        var tindex = record.appLists.findIndex(
          (x) => x.id === record.id_Default_App,
        )
        // console.log('tindex', tindex)
        return record.appLists[tindex].appName
      }
    },
    hide(index) {
      this.data[index].changePassPop = false
      this.popinput = {
        password: '',
        re_password: '',
      }
    },
    sendToForm(action, data = {}) {
      this.$refs.formModal.showModal(action, data)
    },
    // async getMaster() {
    //   var res = await lou.customUrlGet('permission', false, true)
    //   var res1 = await lou.customUrlGet('roles', false, true)
    //   if (res) {
    //     this.datapermission = res.data
    //   }
    //   if (res1) {
    //     this.dataroles = res1.data
    //   }
    //   this.getData()
    // },
    async getData() {
      var res = await lou.customUrlGet('gaji/' + this.table, false, true)
      if (res) {
        this.data = []
        this.data = this.$g.clone(res.data)
        this.backupdata = this.$g.clone(res.data)
      }
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete(
            'gaji/' + this.table + '/' + deldata.id, true, true,
          )
          if (response) {
            this.getData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            // this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
  },
}
</script>
